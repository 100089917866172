import React, { useState } from "react";
import "../styles/hamburgers.min.css";
import { navHolder, burgerHolder } from "./Nav.module.scss";

import { motion } from "framer-motion";
import { Link } from "gatsby";
import VincentV from "../images/svgs/VincentV";

import useWhatsOn from "../hooks/useWhatsOn";
import WhatsOnBadge from "../components/WhatsOnBadge";
import { FacebookSvg, InstagramSvg } from "../images/svgs/FooterSvgs";

const variants = {
  initial: {
    opacity: 0,
    x: "100%",
  },
  hidden: {
    opacity: [null, 0, 0],
    x: ["0%", "0%", "100%"],

    transition: {
      duration: 1,
    },
  },
  visible: {
    x: ["0%", "0%"],
    opacity: 1,
    transition: {
      duration: 0.5,
    },
  },
};

export default function Nav() {
  const [navOpen, setNavOpen] = useState(false);
  const whatsOnInfo = useWhatsOn();

  return (
    <>
      <div className={burgerHolder}>
        <button
          className={`hamburger hamburger--slider
      ${navOpen ? "is-active" : ""}`}
          type="button"
          onClick={() => setNavOpen(!navOpen)}
          id="navToggle"
        >
          <span className="hamburger-box">
            <span className="hamburger-inner">Toggle Nav</span>
          </span>
        </button>
      </div>
      <div className={navHolder}>
        <motion.ul
          variants={variants}
          initial="initial"
          animate={navOpen ? "visible" : "hidden"}
          exit="post"
          key="linksHolder"
          className={`linksHolder`}
          onClick={() => setNavOpen(false)}
          onKeyDown={(e) => {
            if (e.code === "Escape") setNavOpen(false);
          }}
          aria-labelledby="navToggle"
        >
          <li className="homepageLink">
            <Link to="/" title="Back to the Homepage" activeClassName="active">
              <span>The Vincent Hotel Homepage</span>
              <VincentV />
            </Link>
          </li>
          <li>
            <Link to="/our-rooms" title="Our Rooms" activeClassName="active">
              Our Rooms
            </Link>
          </li>
          <li>
            <Link
              to="/food-and-drink"
              title="Food & Drink"
              activeClassName="active"
            >
              Food & Drink
            </Link>
          </li>
          <li>
            <Link
              to="/weddings-and-events"
              title="Weddings & Events"
              activeClassName="active"
            >
              Weddings & Events
            </Link>
          </li>
          <li>
            <Link
              to="/health-and-beauty"
              title="Health & Beauty"
              activeClassName="active"
            >
              Health & Beauty
            </Link>
          </li>
          <li>
            <Link to="/vouchers" title="Gift Vouchers" activeClassName="active">
              Gift Vouchers
            </Link>
          </li>
          {whatsOnInfo.whatsOnPageOptions.showWhatsOnLinkInMenu && (
            <li>
              <Link
                to="/whats-on"
                title="What's On at The Vincent"
                activeClassName="active"
              >
                What's On?
              </Link>
            </li>
          )}
          <li>
            <Link
              to="/weddings-and-events/christmas"
              title="Christmas at The Vincent"
              activeClassName="active"
            >
              Christmas
            </Link>
          </li>
          <li>
            <Link to="/contact" title="Contact" activeClassName="active">
              Contact
            </Link>
          </li>
          <li className="socials">
            <a
              className="facebook"
              href="https://www.facebook.com/thevincenthotel/"
              title="The Vincent Hotel on Facebook"
              target="_blank"
              rel="noreferrer"
            >
              <FacebookSvg />
            </a>
            <a
              className="instagram"
              href="https://www.instagram.com/thevincent.southport/"
              title="The Vincent Hotel on Instagram"
              target="_blank"
              rel="noreferrer"
            >
              <InstagramSvg />
            </a>
          </li>
          <WhatsOnBadge />
        </motion.ul>
      </div>
    </>
  );
}
